
import { readerOutline, albumsOutline, addCircleOutline, listOutline, pencilOutline, closeOutline, logOutOutline } from 'ionicons/icons'
import { ref }				from 'vue'
import { useRoute } 		from 'vue-router'
import { alertController } 	from '@ionic/vue'
import { mapGetters } 		from 'vuex'
import { Network } 			from '@capacitor/network'
import { toastController } 	from '@ionic/vue'


export default {
	data() {
		return {
			readerOutline,
			albumsOutline,
			listOutline,
			addCircleOutline,
			pencilOutline,
			closeOutline,
			logOutOutline,
			connectionToast: null,
			isConnected: true,
			selectedIndex: null,
			route: null
		}
	},

	computed: {
		isSelected(url)  {
			return url === this.route.path ? 'selected' : ''
		},

		...mapGetters('shoppingList', [
			'lists'
		]),

		...mapGetters('wAuth', [
			'isLoggedIn'
		]),

		pages() {
			return this.lists.map(list => {
				return {
					title: list.title,
					url: `/list/${list.id}`,
					iosIcon: readerOutline,
					mdIcon: readerOutline
				}
			})
		}
	},

	created() {
		if (this.isLoggedIn) {
			this.$store.dispatch('shoppingList/loadLists')
		}

		this.route 			= useRoute()
		this.selectedIndex 	= ref(0)
	},

	async mounted() {
		Network.addListener('networkStatusChange', status => {
			this._internetConnectionToast(status.connected)
		})

		await Network.getStatus()
			.then(status => {
				this._internetConnectionToast(status.connected)
			})
	},

	methods: {
		async addNew() {
			const alert = await alertController.create({
				header: 'Enter list name',
				inputs: [{
					name: 'title',
					type: 'text',
					placeholder: 'Family dinner',
				}],
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				},
				{
					text: 'Create',
					handler: async(value) => {
						await this._createList(value.title)
					}
				}]
			})

			return alert.present()
		},

		async _createList(title) {
			this.$wLoader.startLoading('')

			try {
				const shoppingList = await this.$store.dispatch('shoppingList/createList', {
					title: title.length ? title : 'Unnamed list',
					items: []
				})

				this.$refs.menu.$el.close()
				this.$router.push({name: 'List detail', params: {id: shoppingList.id}})
			} catch (error) {
				console.log(error)
			}

			this.$wLoader.clearLoading()
		},

		async logout() {
			this.$wLoader.startLoading('Logging out...')
			await this.$store.dispatch('wAuth/logout')

			// this.$router.replace({name: 'login'})
			window.open(location.origin, "_self")
		},

		async _internetConnectionToast(isConnected) {
			this.isConnected = isConnected

			if (!isConnected) {
				this.connectionToast = await toastController.create({
					message: 'No internet connection!',
					duration: 0,
					color: 'danger',
					position: 'bottom'
				})

				this.connectionToast.present()
			}

			if (isConnected && this.connectionToast) {
				this.connectionToast.dismiss()
				this.connectionToast = null
			}	
		}
	}
}
