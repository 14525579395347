import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import wroute from '@/plugins/w/route'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '/lists'
	},
	wroute('Login', 	'/login', 		() => import('@/plugins/appshoppinglist/login/Login.vue')),
	wroute('Register', 	'/register', 	() => import('@/plugins/appshoppinglist/register/Register.vue')),
	wroute('Lists', 	'/lists', 		() => import('@/plugins/lib@shoppinglist/list/list.vue'), true),
	wroute('List', 		'/list/:id', 	() => import('@/plugins/lib@shoppinglist/detail/detail.vue'), true),
	wroute('Summary', 	'/summary', 	() => import('@/plugins/lib@shoppinglist/summary/Summary.vue'), true)
]

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes
})

export default router
