import axios from 'axios'
import store from '@/store'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error) {
	if (!error.response || error.response.status != 401) {
		return Promise.reject(error)
	}

	if (!isRefreshing) {
		isRefreshing = true

		refreshHook = new Promise((resolve, reject) => {
			store.dispatch('wAuth/refreshToken')
				.then((token) => resolve(token))
				.catch((error) => reject(error))
				
		})

		return await refreshHook
			.then(async(token) => {
				error.config.headers = {
					'Authorization': `bearer ${token}`
				}

				return Promise.resolve(await axios.request(error.config))
			})
			.catch(err => Promise.reject(err))
			.finally(() => isRefreshing = false)
	}
}

axios.interceptors.response.use(
	(response) => response,
	(err) => _unauthorised(err)
)
