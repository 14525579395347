import wAxios from '@/plugins/w/axios'

export default {
	namespaced: true,

	state: {
		lists: JSON.parse(localStorage.getItem('hl_shoppingListItems') || '[]' ),
	},

	mutations: {
		update_lists(state: any, data: any) {
			state.lists = data.lists
			localStorage.setItem('hl_shoppingListItems', JSON.stringify(data.lists))
		}
	},

	actions: {
		loadLists({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				wAxios.get_auth_data('v1/shopping-lists')
					.then((response: any) => {
						const lists = response

						commit('update_lists', {
							lists
						})

						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},

		createList({dispatch, state}: {dispatch: any; state: any}, data) {
			state.lists.push(data)
			
			return new Promise((resolve, reject) => {
				wAxios.post_auth_data('v1/shopping-lists', data)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		updateList({dispatch, state}: {dispatch: any; state: any}, data) {
			let list = state.lists.find(list => list.id == data.id)
			list.title = data.title

			return new Promise((resolve, reject) => {
				wAxios.put_auth_data(`v1/shopping-lists/${data.id}`, data)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		deleteList({dispatch, state}: {dispatch: any; state: any}, listId) {
			state.lists = state.lists.filter(list => list.id != listId)

			return new Promise((resolve, reject) => {
				wAxios.delete_auth_data(`v1/shopping-lists/${listId}`)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		createItem({dispatch, state}: {dispatch: any; state: any}, data) {
			let list = state.lists.find(list => list.id == data.listId)
			list.items.push(data.item)

			return new Promise((resolve, reject) => {
				wAxios.post_auth_data(`v1/shopping-lists/${data.listId}/items`, data.item)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		updateItem({dispatch, state}: {dispatch: any; state: any}, data) {
			let list = state.lists.find(list => list.id == data.listId)
			let item = list.items.find(item => item.id == data.item.id)
			
			if (data.item.priority) item.priority = data.item.priority
			if (data.item.is_checked !== null) item.is_checked = data.item.is_checked
			if (data.item.name) item.name = data.item.name

			return new Promise((resolve, reject) => {
				wAxios.put_auth_data(`v1/shopping-lists/${data.listId}/items/${data.item.id}`, data.item)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		deleteItem({dispatch, state}: {dispatch: any; state: any}, data) {
			let list = state.lists.find(list => list.id == data.listId)
			list.items = list.items.filter(item => item.id != data.itemId)

			return new Promise((resolve, reject) => {
				wAxios.delete_auth_data(`v1/shopping-lists/${data.listId}/items/${data.itemId}`)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		deleteCheckedItems({dispatch, state}: {dispatch: any; state: any}, listId) {
			let list = state.lists.find(list => list.id == listId)
			list.items = list.items.filter(item => !item.is_checked)

			return new Promise((resolve, reject) => {
				wAxios.delete_auth_data(`v1/shopping-lists/${listId}/items`)
					.then((response: any) => {
						dispatch('loadLists')
						resolve(response)
					}).catch(error => {
						dispatch('loadLists')
						reject(error)
					})
			})
		},

		
	},

	getters: {
		lists: (state: any) =>  {
			return state.lists
		},
		
		list: (state: any, id) => {
			return state.lists.find(list => list.id == id)
		}
	}
}